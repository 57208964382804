
enum GTMEventsEnum {
  ProspectRegistered = 'prospectRegistered',
  ON_COMPARER_WIDGET_SUBMITTED = 'onComparerWidgetSubmitted',
  ON_MEETING_PLANNER_SUBMITTED = 'onMeetingPlannerSubmitted',
  ON_BASE_INSTALLMENT_PICKER_SUBMITTED = 'onBaseInstallmentPickerSubmitted',
  MEETING_ARRANGED_ONLINE = 'MeetingArrangedOnline',
  STEP_SUBMIT_PROSPECT_CONTACT_FORM = 'stepSubmitProspectContactForm',
  STEP_SUBMIT_CONSULTATION = 'stepSubmitConsultation',
  STEP_SUBMIT_SMS_CODE = 'stepSubmitSmsCode',
}

export enum GTMActivityEnum {
  CHECK_CREDITABILITY = 'check_creditability',
  CREATE_ACTIVITY = 'create_activity',
  RAISE_CREDITWORTHINESS_RESULT = 'raise_creditworthiness_result',
  RAISE_CREDITWORTHINESS_AD = 'raise_creditworthiness_ad',
  CHECK_OFFERS = 'check_offers',
  SAFE_LOAN = 'check_if_safe_loan'
}

type GTMEvent = {
  event: GTMEventsEnum,
  [key: string]: string | number | boolean | null | undefined;
}

function sendEvent(tag: GTMEvent): void{
  // @ts-ignore
  if(window.dataLayer) {
    if(useEnv().isPreprodOrDev) {
      console.info('!! GTM event send !!', tag);
    }
    (window as any).dataLayer.push(tag);
  } else {
    console.error('There is no GTM initialized');
  }
};

export default function useGTM() {
  const registeredProspect = (prospectId: number) => {
    sendEvent({
      event: GTMEventsEnum.ProspectRegistered,
      prospectId,
    });
  };

  const onComparerWidgetSubmit = (activity: GTMActivityEnum) => {
    sendEvent({
      event: GTMEventsEnum.ON_COMPARER_WIDGET_SUBMITTED,
      [GTMEventsEnum.ON_COMPARER_WIDGET_SUBMITTED]: activity,
    });
  };

  const consultationArranged = () => {
    sendEvent({
      event: GTMEventsEnum.MEETING_ARRANGED_ONLINE,
    });
  };

  const onBaseInstallmentPickerSubmit = (activity: GTMActivityEnum) => {
    sendEvent({
      event: GTMEventsEnum.ON_BASE_INSTALLMENT_PICKER_SUBMITTED,
      [GTMEventsEnum.ON_BASE_INSTALLMENT_PICKER_SUBMITTED]: activity,
    });
  };

  const smsCodeSubmit = () => {
    sendEvent({
      event: GTMEventsEnum.STEP_SUBMIT_SMS_CODE,
    });
  };
  const prospectContactSubmit = () => {
    sendEvent({
      event: GTMEventsEnum.STEP_SUBMIT_PROSPECT_CONTACT_FORM,
    });
  };
  const consultationStepSubmit = () => {
    sendEvent({
      event: GTMEventsEnum.STEP_SUBMIT_CONSULTATION,
    });
  };

  return {
    onComparerWidgetSubmit,
    onBaseInstallmentPickerSubmit,
    registeredProspect,
    consultationArranged,
    smsCodeSubmit,
    prospectContactSubmit,
    consultationStepSubmit,
  };
}
